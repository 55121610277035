import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { queryClient } from "~/shared/api/query-client";
import { USER_OPTIONS } from "~/shared/api/user/user";
import { getImpersonationState } from "~/shared/components/PrivateAppLayout/components/Impersonation";
import { createApiError, ERROR_CODES } from "~/shared/lib/api-error";
import config from "../../config";

export const baseURL = config.api;

const api = axios.create({
  baseURL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

async function authenticationTokenRefresh() {
  try {
    const { tokens } = await fetchAuthSession();

    const { accessToken } = tokens ?? {};
    const { payload } = accessToken ?? {};

    if (!payload) {
      throw new Error("No payload in access token");
    }

    if (isAccessTokenExpired(payload.exp)) {
      const { tokens } = await fetchAuthSession({ forceRefresh: true });
      queryClient.invalidateQueries({ queryKey: USER_OPTIONS.user().queryKey });

      // Return the new token
      return tokens?.accessToken.toString();
    }

    // Return the existing token
    return tokens?.accessToken.toString();
  } catch (_err) {
    // If the user is not authenticated, return null
    queryClient.removeQueries({ queryKey: USER_OPTIONS.user().queryKey });
    return null;
  }
}

// This interceptor intercepts API responses and refreshes the user's access token if necessary
api.interceptors.request.use(async (request) => {
  try {
    const token = await authenticationTokenRefresh();
    request.headers.Authorization = `Bearer ${token}`;

    const impersonationState = getImpersonationState();

    if (impersonationState != null) {
      request.headers["X-BL-Impersonate"] = impersonationState.username;
    }

    return request;
  } catch (err) {
    return request;
  }
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Ensure error is an object
    if (!error || typeof error !== "object") {
      return Promise.reject(createApiError("An invalid error was thrown", error, ERROR_CODES.INVALID_ERROR));
    }

    if (error.__CANCEL__) {
      return Promise.reject(createApiError("Request was cancelled", error, ERROR_CODES.REQUEST_CANCELLED));
    }

    if (!error.response) {
      return Promise.reject(createApiError("Network error occurred", error, ERROR_CODES.NETWORK_ERROR));
    }

    const { status } = error.response;

    switch (status) {
      case 400:
        return Promise.reject(createApiError("Bad request", error, ERROR_CODES.BAD_REQUEST, status));
      case 401:
      case 403:
        return Promise.reject(createApiError("Unauthorized", error, ERROR_CODES.UNAUTHORIZED, status));
      case 404:
        return Promise.reject(createApiError("Resource not found", error, ERROR_CODES.NOT_FOUND, status));
      case 409:
        return Promise.reject(createApiError("Conflict with existing data", error, ERROR_CODES.CONFLICT, status));
      case 418:
        return Promise.reject(createApiError("Server is a teapot", error, ERROR_CODES.TEAPOT, status));
      case 429:
        return Promise.reject(createApiError("Too many requests", error, ERROR_CODES.RATE_LIMITED, status));
      case 500:
        return Promise.reject(createApiError("Internal server error", error, ERROR_CODES.SERVER_ERROR, status));
      default:
        return Promise.reject(createApiError("Unknown error occurred", error, ERROR_CODES.UNKNOWN_ERROR, status));
    }
  },
);

// Refresh token if expired
// This function checks if the user's ID token has expired
function isAccessTokenExpired(expiration?: number): boolean {
  if (expiration === undefined) return true;

  const currentTimeSeconds = Math.round(new Date().getTime() / 1000);
  return expiration < currentTimeSeconds;
}

export default api;
